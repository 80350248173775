
import Vue from 'vue';
import VueQr from 'vue-qr/src/packages/vue-qr.vue';
import { mapGetters } from 'vuex';

import WalletsApi from 'Apis/Wallets';
import WalletAddressRequestData from 'Entities/walletExecutor/WalletAddressRequestData';
import CancelDepositRequestData from 'Entities/walletExecutor/CancelDepositRequestData';
import ApiError from 'Entities/ApiError';
import Transfer, { ITransfer } from 'Entities/privatePresenter/Transfer';
import EmptyResult from 'Entities/walletExecutor/EmptyResult';
import Icon from 'UI/Icon.vue';
import Button from 'Control/Button.vue';
import AssetValue from 'UI/AssetValue.vue';
import { copyTextWithPermissions } from 'Lib/utils/copyTextWithPermissions';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import PublicDataApi from 'Apis/PublicData';
import AssetQuotationRequest from 'Entities/publicPresenter/AssetQuotationRequest';

export default Vue.extend<any, any, any>({
    components: {
        VueQr,
        Icon,
        Button,
        AssetValue,
    },
    data() {
        return {
            time: null,
            showQr: false,
            previousRoute: '/wallets',
            copyTextWithPermissions,
            quotationRate: 0,
            lastQuotationRequest: {
                quotationSymbol: '',
                symbol: '',
            },
            calculatePrecision,
            historyData: {
                assetSymbol: '',
                blockchainName: '',
                createdAt: '',
                id: '',
                quantity: 0,
                sourceAddress: {
                    memo: '',
                    address: '',
                },
                destinationAddress: {
                    memo: '',
                    address: '',
                },
            },
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
        }),
        isThemeDark() {
            return this.$store.getters.isThemeDark;
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        quotationAssetPrecision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
    },
    methods: {
        async getQuotationRate() {
            if (!this.historyData.assetSymbol || !this.quotationAssetSymbol) {
                this.quotationRate = 0;
            }

            try {
                // check for not duplicating requests
                if (
                    this.lastQuotationRequest.quotationSymbol === this.quotationAssetSymbol
                    && this.lastQuotationRequest.symbol === this.historyData.assetSymbol
                ) {
                    return;
                }
                this.lastQuotationRequest = {
                    quotationSymbol: this.quotationAssetSymbol,
                    symbol: this.historyData.assetSymbol,
                };
                const { data: quotation } = await PublicDataApi.publicGetAssetQuotation(new AssetQuotationRequest(this.lastQuotationRequest));
                this.quotationRate = quotation.rate;
            } catch {
                this.quotationRate = 0;
            }
        },
        showNotification() {
            this.$store.dispatch('Notificator/showSuccessNotification', 'Value Has Successfully Copied To Your Clipboard');
        },
        setTimer(time) {
            if (this.intervalId) {
                clearInterval(this.intervalId);
            }
            if (time) {
                if (time < 900000) {
                    this.time = 900000 - time;
                } else {
                    this.time = 0;
                }
            } else {
                this.time = 900000;
            }
            this.intervalId = setInterval(() => {
                if (this.time === 0) {
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                } else {
                    this.time -= 1000;
                }
            }, 1000);
        },
        validateTimeNumber(num) {
            if (Number(num) < 10) {
                return `0${num}`;
            }
            return String(num);
        },
        async setModalData(e) {
            try {
                const { data: res } = await WalletsApi.getAddress(new WalletAddressRequestData({
                    accountId: e.toAccountId,
                    assetSymbol: e.assetSymbol,
                    blockchainName: e.blockchainName,
                }));
                this.historyData.assetSymbol = e.assetSymbol;
                this.historyData.blockchainName = e.blockchainName;
                this.historyData.createdAt = e.createdAt;
                this.historyData.id = e.id;
                this.historyData.sourceAddress.memo = e.memo;
                this.historyData.sourceAddress.address = e.address;
                this.historyData.destinationAddress.memo = res instanceof EmptyResult ? '' : res.memo;
                this.historyData.destinationAddress.address = res instanceof EmptyResult ? '' : res.address;
                this.historyData.quantity = e.quantity;
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during setting modal data');
                }
            }
        },
        async cancelTransfer(id) {
            try {
                await WalletsApi.cancelDeposit(new CancelDepositRequestData({
                    transferId: id,
                }));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Error during cancelling deposit');
                }
            } finally {
                await this.$router.push(this.previousRoute);
            }
        },
    },
    async mounted() {
        await this.setModalData(new Transfer(this.$route.query as ITransfer));
        const { previousRoute } = this.$route.query;
        this.previousRoute = previousRoute;

        await this.getQuotationRate();
    },
    watch: {
        async quotationAssetSymbol() {
            await this.getQuotationRate();
        },
    },
});
